import { pickupItemsFromSessionStorage, dataLayerEvent } from './dataLayer';
import { DATA_LAYER_EVENT_TYPE } from './constants';
import { BILLING_CYCLE_TYPE } from '../constants';
import { getBillingCycle, getCoupon, waitForQuotingData } from './util';
import { currencyFormat } from '../util';
import { getFeatureFlagFromStorage } from '../utils/featureFlags.utils';

export const dataLayerBeginCheckout = async () => {
  const { QuoteSiteEBDataLayer } = getFeatureFlagFromStorage();
  if (!QuoteSiteEBDataLayer) {
    return;
  }

  dataLayerEvent({ ecommerce: null });

  const items = await pickupItemsFromSessionStorage();
  const quoting = await waitForQuotingData();

  const billingCycle = quoting.isMonthlyPlan
    ? BILLING_CYCLE_TYPE.monthly
    : BILLING_CYCLE_TYPE.annualy;

  const summary = quoting.rateSummary.QuoteSummaries.find(
    (item) => item.BillingCycle === billingCycle
          && item.PaymentType === quoting.paymentMethod.paymentMethodType.value,
  ) || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

  const petsList = await items;

  const petListWithoutPetQuoteId = petsList.map((item) => {
    const itemCopy = { ...item };
    delete itemCopy.petQuoteId;
    return itemCopy;
  });

  const ecommerce = {
    billing_cycle: getBillingCycle(quoting),
    coupon: getCoupon(quoting),
    currency: 'USD',
    items: petListWithoutPetQuoteId,
    language: 'en',
    value: Number(currencyFormat({ value: summary.TodaysTotal })),
  };

  dataLayerEvent({
    ecommerce,
    event: DATA_LAYER_EVENT_TYPE.BEGIN_CHECKOUT,
    language: 'en',
  });
};
