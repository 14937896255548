import React, { useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  toggleYourInfoScrollToTop,
  sendFSCQuoteLead,
  saveQuote,
  setPowerUpErrors,
  validateAllPetsPowerups,
} from '../../actions';

import arrowRightIcon from '../../assets/next-citron.svg';
import {
  URL_LIVE_VET_YOUR_INFO,
  URL_ROOT_QUOTE, URL_YOUR_INFO,
} from '../../routes';
import {
  validatePowerupsSelected,
  currencyFormat,
  formatDate,
  useFSCLeads,
} from '../../util';
import { dataLayerAddToCart } from '../../gtmDataLayer/dataLayerAddToCard';
import { useOnBodyScrollEvent, useRedesign } from '../../hooks';

import './PriceStickyBar.css';
import { TemporaryMessage } from './TemporaryMessage';
import { useStickyBarHeight } from '../landing/StickyBarHeightProvider';
import { QuotingSteps, SAVE_QUOTE_TYPE } from '../../constants';

const PriceStickyBar = () => {
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    isMonthlyPlan,
    quoteSelectedValues,
    utmParams,
    currentQuoteStep,
    petQuoteSelected,
  } = store;

  const location = useLocation();
  const { t } = useTranslation('quoting');
  const dispatch = useDispatch();
  const history = useHistory();
  const isRedesign = useRedesign();
  const { isOnTopScreen } = useOnBodyScrollEvent();
  const { setStickyBarHeight } = useStickyBarHeight();
  const ref = useRef(null);
  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;

  const nextStepLabel = useMemo(() => {
    if (location.pathname.toLocaleLowerCase() === URL_ROOT_QUOTE) {
      return t('priceStickyBar.stepsDescription.enrollment');
    }

    if (location.pathname.toLocaleLowerCase() === URL_YOUR_INFO) {
      return t('priceStickyBar.stepsDescription.continue');
    }

    return t('priceStickyBar.stepsDescription.purchase');
  }, [location, t]);

  const quoteTotal = useMemo(() => {
    const allQuotes = quoteSelectedValues
      .reduce((previous, current) => {
        if (!isMonthlyPlan) {
          return previous + current.totalAnnual;
        }

        return previous + current.totalMontly;
      }, 0);

    if (!allQuotes) {
      return '0.00';
    }

    return currencyFormat({ value: allQuotes });
  }, [quoteSelectedValues, isMonthlyPlan]);

  const stickyBarRef = useRef(null);

  const adjustMainContentPadding = () => {
    const mainContent = document.querySelector('.App');

    if (!mainContent || !stickyBarRef.current) return;

    const barHeight = stickyBarRef.current.offsetHeight;

    mainContent.style.paddingBottom = `${barHeight}px`;
  };

  useEffect(() => {
    if (isSecondStep) {
      const mainContent = document.querySelector('.App');
      mainContent.style.paddingBottom = '0px';
    }

    adjustMainContentPadding();

    const resizeObserver = new ResizeObserver(() => {
      if (isSecondStep) {
        const mainContent = document.querySelector('.App');
        mainContent.style.paddingBottom = '0px';
      }

      adjustMainContentPadding();
    });

    const { current } = stickyBarRef;

    if (stickyBarRef.current) {
      resizeObserver.observe(stickyBarRef.current);
    }

    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
  }, [isSecondStep, stickyBarRef]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setStickyBarHeight(ref.current.offsetHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setStickyBarHeight]);

  const cycle = isMonthlyPlan
    ? t('priceStickyBar.paymentCycle.monthly')
    : t('priceStickyBar.paymentCycle.annually');

  const numberOfPets = useMemo(() => {
    if (data && data.ebPetQuoteResponseList) {
      return data.ebPetQuoteResponseList.length;
    }

    return 0;
  }, [data]);

  const onContinueClick = () => {
    const petQuoteRateItem = data.ebPetQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    const allPetsAreValid = dispatch(validateAllPetsPowerups());
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      return;
    }
    if (isRedesign && !allPetsAreValid) {
      return;
    }
    dispatch(toggleYourInfoScrollToTop(true));

    // GTM
    dataLayerAddToCart();

    if (useFSCLeads) {
      dispatch(sendFSCQuoteLead());

      const {
        customerValidationResponse,
        newCustomer,
        parameters,
        guidData,
        saveQuote: {
          quoteId,
        },
      } = store;
      const {
        groupCode,
        groupCodeDscr,
        ebPetQuoteResponseList,
        zipCode,
      } = data;
      const cloudClientId = customerValidationResponse?.id || 0;
      const { GuID, EmployerEnrollmentPeriodEBs } = guidData;
      dispatch(saveQuote({
        cloudClientId,
        ebPetQuoteResponseList,
        EffectiveDate:
          formatDate(EmployerEnrollmentPeriodEBs[0].PolicyEffectiveDate),
        email: newCustomer.email || parameters.email,
        firstName: newCustomer.name || '',
        groupCode,
        groupCodeDscr,
        GuID,
        lastName: newCustomer.lastName || '',
        origin: window.location.origin,
        quoteId,
        quoteSelectedValues,
        QuoteSubId: store.subId,
        quoteType: SAVE_QUOTE_TYPE.auto,
        zipCode,
      }));
    }
    history.push({
      pathname: URL_YOUR_INFO,
      search: queryString.stringify(utmParams),
    });
  };

  if ([URL_YOUR_INFO, URL_LIVE_VET_YOUR_INFO]
    .toLocaleString().toLocaleLowerCase().split(',')
    .includes(location.pathname.toLocaleLowerCase())) {
    return null;
  }

  return (
    <section
      ref={stickyBarRef}
      aria-label={t('priceStickyBar.priceBanner')}
      className="Price-sticky-bar-container"
    >
      <TemporaryMessage />

      <div className="Price-sticky-bar-price-inner-container">
        <div className="Price-sticky-bar-price-container">
          <span>
            <span className="Price-sticky-bar-price">${quoteTotal}</span>

            <span className="Price-sticky-bar-price-cycle">{cycle}</span>
          </span>

          {isOnTopScreen ? (
            <span className="Price-sticky-bar-price-details">
              {t('priceStickyBar.petsIncluded',
                { count: numberOfPets, numberOfPets })}
            </span>
          ) : null}
        </div>

        <button
          className="Price-sticky-bar-continue-button"
          onClick={onContinueClick}
          type="button"
        >
          {t('priceStickyBar.continueButton', { nextStepLabel })}

          <img
            alt=""
            className="Price-sticky-bar-button-icon"
            src={arrowRightIcon}
          />
        </button>
      </div>
    </section>
  );
};

export { PriceStickyBar };
