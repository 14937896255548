import { isProdEnv, getSessionStorage, currencyFormat } from '../util';
import {
  NO_PARTNER_CODE,
  STORE_ATTRIBUTE,
  DATA_LAYER_EVENT_TYPE,
  EMPTY_STRING,
  FULL_POLICY,
  NO_POWERUPS,
  ALL_POWERUPS,
} from './constants';
import {
  waitForQuotingData,
  getPetAge,
  getBillingCycle,
  getCoupon,
  getAffiliation,
} from './util';
import { PetType, BILLING_CYCLE_TYPE } from '../constants';

const dataLayerEvent = (data) => {
  (window.dataLayer || []).push(data);

  if (!isProdEnv) {
    // eslint-disable-next-line no-console
    console.log('dataLayerEvent', data);
  }
};

const getModifierNameById = (modifier, id) => {
  if (!modifier || !id) {
    throw new Error();
  }

  if (modifier.Id === id) {
    return modifier.TitleText || modifier.AppText || '';
  }

  if (modifier.BundleInsuranceModifiers) {
    const subModifier = modifier.BundleInsuranceModifiers.find(
      (item) => item.Id === id,
    );

    if (subModifier) {
      return subModifier.TitleText || subModifier.AppText || '';
    }
  }
  return '';
};

const getModifiersName = ({ selectedModifiers, modifiers }) => {
  if (!selectedModifiers || !modifiers) {
    return {
      modifiersName: NO_POWERUPS,
    };
  }

  const selectedModifiersIds = selectedModifiers
    .filter((modifier) => modifier.isSelected)
    .map((modifier) => modifier.id);

  // eslint-disable-next-line max-len
  const modifiersWithTitle = selectedModifiersIds.map((selectedModifierId) => modifiers.find((modifier) => getModifierNameById(modifier, selectedModifierId)));

  const modifiersName = modifiersWithTitle.map(
    (modifier) => modifier.TitleText || modifier.AppText,
  );

  const modifiersListNames = [...new Set(modifiersName)];

  const modifiersNameFormated =
      modifiersListNames.length > 1 ? ALL_POWERUPS : modifiersListNames[0];

  return {
    modifiersName: modifiersNameFormated || NO_POWERUPS,
  };
};

const getPlanName = ({ selectedPlan, plans }) => {
  if (!selectedPlan || !plans) {
    throw new Error();
  }

  const planFounded = plans.find((plan) => plan.Id === selectedPlan);

  return {
    planName: planFounded?.Name || 'Custom Plan',
  };
};

const pickupPartnerCodeFromSessionStorage = () => {
  try {
    // you can get this info from quoting
    // parameters.p
    // parameters.groupCode
    // data.groupCode
    const storageData = getSessionStorage(STORE_ATTRIBUTE.QUOTING);
    const { groupCode } = storageData?.data;

    if (!storageData || !groupCode) {
      throw new Error();
    }
    return {
      partnerCode: groupCode || NO_PARTNER_CODE,
    };
  } catch (_) {
    return {
      partnerCode: NO_PARTNER_CODE,
    };
  }
};

const pickupIncompleteTransactionsFromSessionStorage = () => {
  try {
    const storageData = getSessionStorage(STORE_ATTRIBUTE.QUOTING);
    const { purchase } = storageData;
    const { incompletePurchases } = purchase;
    return {
      incompletePurchases: incompletePurchases || [],
    };
  } catch (_) {
    return {
      policyNumbers: [],
      transactionId: '',
    };
  }
};

const pickupTransactionIdFromSessionStorage = () => {
  try {
    const storageData = getSessionStorage(STORE_ATTRIBUTE.QUOTING);
    const { purchase } = storageData;
    const { customerId } = purchase;

    if (!storageData || !purchase || !customerId) {
      throw new Error();
    }

    const policyNumbers = purchase.policyNumbers.map(
      (policy) => policy.PolicyNumber,
    );

    const transactionId = `${customerId}_${policyNumbers?.join('_')}`;

    return {
      policyNumbers: policyNumbers || [],
      transactionId: transactionId || '',
    };
  } catch (_) {
    return {
      policyNumbers: [],
      transactionId: '',
    };
  }
};

const calcTotalMontlyAmountFromItems = (items) => {
  try {
    if (!items || !items.length) {
      throw new Error();
    }

    const totalAmount = items.reduce((acc, item) => {
      const { price, quantity } = item;
      return acc + price * quantity;
    }, 0);

    return parseFloat(parseFloat(totalAmount).toFixed(2)) || 0;
  } catch (_) {
    return 0;
  }
};

const pickupItemsFromSessionStorage = async (sessionStorage) => {
  try {
    const storageData =
     sessionStorage ?? getSessionStorage(STORE_ATTRIBUTE.QUOTING);
    const { quoteSelectedValues } = storageData;
    const { ebPetQuoteResponseList } = storageData?.data;
    const { InsuranceModifiersEB } = storageData.data.insuranceProductEB;
    const { PrePackagedPlans } = storageData?.data?.ebPetQuoteResponseList[0];

    if (
      !storageData
        || !quoteSelectedValues
        || !ebPetQuoteResponseList
        || !InsuranceModifiersEB
        || !PrePackagedPlans
    ) {
      throw new Error();
    }

    const quoting = await waitForQuotingData();

    const itemsList = quoting.data?.petQuoteResponseList?.filter(
      (item) => quoteSelectedValues.some(
        (quoteSelected) => item.petQuoteId === quoteSelected.quoteId,
      ),
    ).map(
      (pet, index) => ({
        affiliation: getAffiliation(quoting),
        item_brand: 'Figo',
        item_category: getModifiersName({
          modifiers: InsuranceModifiersEB,
          selectedModifiers: pet.modifiers,
        }).modifiersName,
        item_category2:
        pet.petType === PetType.Dog.value ? PetType.Dog.name : PetType.Cat.name,
        item_category3: pet.breedName,
        item_category4: getPetAge(pet),
        item_id: pet.temporal_item_id,
        item_name: FULL_POLICY,
        item_variant: getPlanName({
          plans: PrePackagedPlans,
          selectedPlan: quoteSelectedValues[index].selectedPlanPackaged,
        }).planName,
        petQuoteId: pet.petQuoteId,
        price: quoteSelectedValues[index].total,
        quantity: 1,
      }),
    );

    return itemsList;
  } catch (_) {
    return [
      {
        item_addon: EMPTY_STRING,
        item_id: EMPTY_STRING,
        item_name: FULL_POLICY,
        item_variant: EMPTY_STRING,
        price: 0,
        quantity: 1,
      },
    ];
  }
};

const getDataLayerPurchaseEvent = async () => {
  try {
    let items = await pickupItemsFromSessionStorage();
    if (!items || !items.length) {
      throw new Error();
    }

    const { incompletePurchases } =
        pickupIncompleteTransactionsFromSessionStorage();
    if (incompletePurchases.length) {
      items = items.filter((item) => !incompletePurchases
        .some((purchase) => item.petQuoteId === purchase.PetQuoteId));
    }

    const { transactionId, policyNumbers } =
        pickupTransactionIdFromSessionStorage();

    if (!transactionId || !policyNumbers) {
      throw new Error();
    }

    const { partnerCode } = pickupPartnerCodeFromSessionStorage();
    if (!partnerCode) {
      throw new Error();
    }

    const monthlyPriceTotal = calcTotalMontlyAmountFromItems(items);
    if (!monthlyPriceTotal) {
      throw new Error();
    }

    const quoting = await waitForQuotingData();

    const billingCycle = quoting.isMonthlyPlan
      ? BILLING_CYCLE_TYPE.monthly
      : BILLING_CYCLE_TYPE.annualy;

    const summary = quoting.rateSummary.QuoteSummaries.find(
      (item) => item.BillingCycle === billingCycle
          && item.PaymentType === quoting.paymentMethod.paymentMethodType.value,
    ) || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

    const itemsWithoutPetQuoteId = items.map((item) => {
      const itemCopy = { ...item };
      delete itemCopy.petQuoteId;
      return itemCopy;
    });

    return {
      ecommerce: {
        affiliation: partnerCode,
        billing_cycle: getBillingCycle(quoting),
        coupon: getCoupon(quoting),
        currency: 'USD',
        items: itemsWithoutPetQuoteId,
        language: 'en',
        transaction_id: transactionId,
        value: Number(currencyFormat({ value: summary.TodaysTotal })),
      },
      email_address: quoting.parameters.email,
      event: DATA_LAYER_EVENT_TYPE.PURCHASE,
      language: 'en',
    };
  } catch (e) {
    return {
      ecommerce: {
        affiliation: EMPTY_STRING,
        currency: 'USD',
        items: EMPTY_STRING,
        transaction_id: EMPTY_STRING,
        value: 0,
      },
      event: DATA_LAYER_EVENT_TYPE.PURCHASE,
    };
  }
};

export {
  dataLayerEvent,
  pickupPartnerCodeFromSessionStorage,
  pickupItemsFromSessionStorage,
  getDataLayerPurchaseEvent,
};
