import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import check from '../../../assets/check-blue-shade-dark.svg';
import downArrow from '../../../assets/purchase/down-arrow.svg';
import personFeedsDog from '../../../assets/purchase/person-feeding-dog.svg';

import { URL_PURCHASE_RETRY } from '../../../routes';
import {
  removeQuoteSelectedValueById,
  resetPurchaseData,
} from '../../../actions';
import { useOneIncScript, useResponsive } from '../../../hooks';

import PurchasePage from './PurchasePage';
import { QuestionsInfo } from '../QuestionsInfo';
import { PetPurchaseResult } from './PetPurchaseResult';
import { PetCloudSection } from './PetCloudSection';
import { TextAlertsSection } from './TextAlertsSection';
import { OneIncModal } from '../../common/OneIncModal';

import './PurchaseSuccess.css';
import { resetOneInInformation } from '../../../actions/oneInc';
import { useRetryPurchase } from '../../../util';
import { dataLayerPurchase } from '../../../gtmDataLayer';

const PURCHASE_ERROR_TYPES = {
  paymentMethod: 'Payment',
};

const PurchaseSuccess = () => {
  const { t } = useTranslation('purchaseResult');
  const store = useSelector(({ quoting }) => quoting);
  const { isMobile } = useResponsive();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    purchase: {
      incompletePurchases,
      policyNumbers,
    },
  } = store;

  useOneIncScript();

  const isFullySuccess = incompletePurchases.length === 0
    && policyNumbers.length > 0;

  const isLiveVet = !incompletePurchases?.length && !policyNumbers?.length;

  const title = useMemo(() => {
    if (incompletePurchases.length && policyNumbers.length) {
      return t('titlePartialSuccess');
    }
    if (incompletePurchases.length && !policyNumbers.length) {
      return t('titleFail');
    }
    if (!policyNumbers.length) {
      return t('titleSuccessLiveVet');
    }

    return t('titleFullySuccess');
  }, [incompletePurchases, policyNumbers, t]);

  const text = useMemo(() => {
    if ((incompletePurchases.length && policyNumbers.length)
      || (incompletePurchases.length && !policyNumbers.length)) {
      return (
        <>
          {t('textFailPart1')}&nbsp;
          <strong>{t('textFailPart2')}</strong>
          &nbsp;{t('textFailPart3')}
        </>
      );
    }

    return t('textSuccess', {
      count: policyNumbers.length,
      pet: policyNumbers[0]?.PetName,
    });
  }, [incompletePurchases, policyNumbers, t]);

  const handleOnComplete = React.useCallback(() => {
    history.push(URL_PURCHASE_RETRY);
  }, [history]);

  const incompletePurchasesByPayment = incompletePurchases
    .filter(((item) => item.ErrorType === PURCHASE_ERROR_TYPES.paymentMethod));
  const incompletePurchasesByOther = incompletePurchases
    .filter(((item) => item.ErrorType !== PURCHASE_ERROR_TYPES.paymentMethod));

  useLayoutEffect(() => {
    dispatch(resetPurchaseData());
    dispatch(resetOneInInformation());
  }, [dispatch]);

  useEffect(() => {
    if (incompletePurchasesByPayment.length === 0) {
      return;
    }

    [
      ...policyNumbers,
      ...incompletePurchasesByOther,
    ].forEach((policyNumber) => {
      dispatch(removeQuoteSelectedValueById(policyNumber.PetQuoteId));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (store?.purchase?.loading || store?.searchParameters) {
      return;
    }

    history.push('/');
  }, [history, store]);

  // GTM
  useEffect(() => {
    dataLayerPurchase();
  }, []);

  return (
    <PurchasePage success={isFullySuccess}>
      {/* OneInc modal create here */}
      <div id="portalOneContainer" />

      <div
        className={'Purchase-result-container '
          + `Purchase-result-card ${isFullySuccess
            ? 'Purchase-result-success-container' : ''}`}
      >
        <div className={`Purchase-result-content ${isLiveVet
          ? 'Purchase-livevet' : ''}`}
        >
          <div className={'Purchase-result Pet-purchase-result-left'
            + ' Purchase-result-card-mobile'}
          >

            <div className={isLiveVet ? 'Purchase-success-title' : ''}>
              {isLiveVet && (
                <img alt="" className="check-icon-livevet" src={check} />
              )}
              <h1>{title}</h1>
            </div>

            <p className="Purchase-result-text">{text}</p>

            {isMobile && isLiveVet && (
              <div className="Livevet-image">
                <img alt="" src={personFeedsDog} />
              </div>
            )}

            <div
              className={'Purchase-result-list '
                + `${isFullySuccess ? 'Purchase-result-list-small' : ''}`}
              role="list"
            >
              {useRetryPurchase ? (
                <>
                  {incompletePurchasesByPayment.length > 0 && (
                    <div className="Purchase-result-fail-container-ul">
                      <ul>
                        {incompletePurchasesByPayment.map((pet) => (
                          <li key={pet.PetName}>
                            <PetPurchaseResult
                              errorMessage={pet.PaymentError}
                              isCat={pet.IsCat}
                              petName={pet.PetName}
                              success={false}
                            />
                          </li>
                        ))}
                      </ul>

                      {!isLiveVet && (
                        <OneIncModal
                          onComplete={handleOnComplete}
                          styleUpdate
                        />
                      )}
                    </div>
                  )}

                  {incompletePurchasesByOther.length > 0 && (
                    <ul>
                      {incompletePurchasesByOther.map((pet) => (
                        <li key={pet.PetName}>
                          <PetPurchaseResult
                            errorMessage={pet.PaymentError}
                            errorType={pet.ErrorType}
                            isCat={pet.IsCat}
                            petName={pet.PetName}
                            success={false}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <>
                  {incompletePurchases.length > 0 && (
                    <ul>
                      {incompletePurchases.map((pet) => (
                        <li key={pet.PetName}>
                          <PetPurchaseResult
                            isCat={pet.IsCat}
                            petName={pet.PetName}
                            success={false}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}

              {policyNumbers.map((pet) => (
                <li key={pet.PolicyNumber}>
                  <PetPurchaseResult
                    isCat={pet.IsCat}
                    petName={pet.PetName}
                    policyNumber={pet.PolicyNumber}
                    success
                  />
                </li>
              ))}
            </div>
          </div>

          {!isMobile && isLiveVet && (
            <div className="Livevet-image">
              <img alt="" src={personFeedsDog} />
            </div>
          )}

          {!isFullySuccess && !isLiveVet && (
            <div className="Purchase-result-contact">
              <QuestionsInfo isPurchase />
            </div>
          )}
        </div>

        {((!isFullySuccess && !!policyNumbers.length) || isLiveVet) && (
          <div className="Pet-cloud-section-no-fully-success">
            <hr aria-hidden />

            <PetCloudSection isCardFooter isFullySuccess={false} />
          </div>
        )}
      </div>

      {isFullySuccess && (
        <>
          <h2 className="Purchase-result-whats-next">{t('whatsNext')}</h2>

          <img
            alt=""
            className="Purchase-result-whats-next-icon"
            src={downArrow}
          />

          <p className="Purchase-result-whats-next-description">
            {t('whatsNextText')}
          </p>

          <div className="Purchase-result-whats-next-cards">
            <div
              className={'Purchase-result-card-flex1'
                + ' Purchase-result-card-mobile'
                + ' Purchase-result-whats-next-card'}
            >
              <PetCloudSection />
            </div>

            {isMobile && (
              <div className="purchase-whats-next-card-separator" />
            )}

            <div
              className={'Purchase-result-card-flex1'
                + ' Purchase-result-card-mobile'
                + ' Purchase-result-whats-next-card'}
            >
              <TextAlertsSection />
            </div>
          </div>
        </>
      )}
    </PurchasePage>
  );
};

export default PurchaseSuccess;
