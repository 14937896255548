import { dataLayerEvent, getDataLayerPurchaseEvent } from './dataLayer';
import { removeSessionStorage } from '../util';
import { getFeatureFlagFromStorage } from '../utils/featureFlags.utils';
import { STORE_ATTRIBUTE, EMPTY_STRING } from './constants';

export const dataLayerPurchase = async () => {
  const { QuoteSiteEBDataLayer } = getFeatureFlagFromStorage();

  if (!QuoteSiteEBDataLayer) {
    return;
  }

  // clear the previous ecommerce data object
  dataLayerEvent({ ecommerce: null });

  getDataLayerPurchaseEvent()
    .then((purchaseEvent) => {
      if (purchaseEvent.ecommerce.items !== EMPTY_STRING) {
        dataLayerEvent(purchaseEvent);
      }
      removeSessionStorage(STORE_ATTRIBUTE.GTM);
    })
    .catch();
};
