import {
  waitForQuotingData,
  getPetAge,
  getCoupon,
  getAffiliation,
} from './util';
import { getFeatureFlagFromStorage } from '../utils/featureFlags.utils';
import { PATH_ALLOWED, DATA_LAYER_EVENT_TYPE, FULL_POLICY } from './constants';
import { PetType } from '../constants';
import {
  dataLayerEvent,
  pickupPartnerCodeFromSessionStorage,
} from './dataLayer';

export const dataLayerViewItem = async ({ pathname = '' }) => {
  const { QuoteSiteEBDataLayer } = getFeatureFlagFromStorage();
  const pathLowerCase = pathname?.toLocaleLowerCase() || '';

  if (!QuoteSiteEBDataLayer
    || !PATH_ALLOWED.VIEW_ITEM.includes(pathLowerCase)) {
    return;
  }

  const quoting = await waitForQuotingData();

  const { partnerCode } = pickupPartnerCodeFromSessionStorage();

  if (!partnerCode) {
    throw new Error();
  }

  const items = quoting.data.petQuoteResponseList.map((pet) => ({
    affiliation: getAffiliation(quoting),
    item_brand: 'Figo',
    item_category2:
    pet.petType === PetType.Dog.value ? PetType.Dog.name : PetType.Cat.name,
    item_category3: pet.breedName,
    item_category4: getPetAge(pet),
    item_id: pet.temporal_item_id,
    item_name: FULL_POLICY,
    price: pet.monthlyPremium,
    quantity: 1,
  }));

  const currentPetSelected = items[quoting.petQuoteSelected - 1];

  const ecommerce = {
    coupon: getCoupon(quoting),
    currency: 'USD',
    items: [currentPetSelected],
    language: 'en',
    value: currentPetSelected.price,
  };

  dataLayerEvent({
    ecommerce,
    email_address: quoting.parameters.email,
    event: DATA_LAYER_EVENT_TYPE.VIEW_ITEM,
    language: 'en',
  });
};
