import { getFeatureFlagFromStorage } from '../utils/featureFlags.utils';
import { pickupItemsFromSessionStorage, dataLayerEvent } from './dataLayer';
import { waitForQuotingData, getCoupon } from './util';
import { DATA_LAYER_EVENT_TYPE } from './constants';

export const dataLayerAddToCart = async () => {
  const { QuoteSiteEBDataLayer } = getFeatureFlagFromStorage();
  if (!QuoteSiteEBDataLayer) {
    return;
  }

  dataLayerEvent({ ecommerce: null });

  const items = await pickupItemsFromSessionStorage();
  const quoting = await waitForQuotingData();

  items.forEach((item) => {
    dataLayerEvent({
      ecommerce: {
        coupon: getCoupon(quoting),
        currency: 'USD',
        items: [item],
        language: 'en',
        value: item.price,
      },
      event: DATA_LAYER_EVENT_TYPE.ADD_TO_CART,
      language: 'en',
    });
  });
};
